export const color = {
  black: "#221F20",
  secondary: "#293E50",
  gray: {
    "900": "#212934",
    "800": "#556377",
    "700": "#6E7A8A",
    "600": "#929FB1",
    "500": "#AEBECD",
    "400": "#CCD4DB",
    "300": "#D5DDE5",
    "200": "#E1E7EC",
    "100": "#F8F9FA",
  },
  accent: {
    error: "#E53E3E",
    warning: "#ED8936",
  },
}

export const space = {
  xs: "4px",
  s: "8px",
  m: "16px",
  l: "24px",
  xl: "32px",
  xxl: "64px",
} as const

export const breakpoint = {
  xsm: "320px",
  sm: "575px",
  md: "767px",
  lg: "1024px",
}

export const typography = {
  type: {
    primary: '"Inter","Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  weight: {
    regular: "400",
    semibold: "600",
    bold: "700",
  },
  size: {
    s1: "14px",
    s2: "16px",
    s3: "18px",
    m1: "21px",
    m2: "24px",
    l1: "32px",
    l2: "48px",
    l3: "60px",
  },
}

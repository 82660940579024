import * as React from "react"
import Container from "./Container"
import styled from "styled-components"
import { space, breakpoint, typography } from "../styles/variables"
import ContactForm from "./ContactForm"

const ServicesWrapper = styled.div`
  background: #221f20;
  color: white;
  padding: ${space.xxl} 0 ${space.xl} 0;

  a {
    color: white;
  }
`

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${space.xl} ${space.xl};
  margin-top: ${space.xxl};

  @media (max-width: ${breakpoint.lg}) {
    grid-template-columns: 1fr;
    margin-top: ${space.l};
    gap: ${space.xl} 0;
  }
`

const Copyright = styled.span`
  opacity: 0.2;
  margin-top: ${space.xxl};
  display: block;
`

const Contact = styled.div`
  margin-top: ${space.xl};
`

const Social = styled.p`
  opacity: 0.9;
  display: grid;
  grid-template-columns: 100px 100px;
  grid-column-gap: ${space.xl};

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Footer = () => (
  <>
    <ServicesWrapper id="contact">
      <Container>
        <h2>Ota yhteyttä</h2>
        <ServicesContainer>
          <div>
            <ContactForm />
          </div>
          <p>
            {" "}
            <p>
              Riina Kivikoski <br /> – Sertifioitu PT ja psyykkinen valmentaja
            </p>
            <Contact>
              <p>
                Sähköposti{" "}
                <a href="mailto:riina@will-do.fi">riina@will-do.fi</a> <br />
              </p>
              <Social>
                <a
                  href="https://www.instagram.com/riinakivikoski"
                  target="_blank"
                >
                  Instagram
                </a>{" "}
                <a
                  href="https://www.linkedin.com/in/riina-kivikoski-361029116/"
                  target="_blank"
                >
                  LinkedIN
                </a>
              </Social>
            </Contact>
          </p>
        </ServicesContainer>
        <Copyright>
          {" "}
          All rights reserved. Copyright {new Date().getFullYear()} WILL-DO by
          Riina, Found Object Oy.
        </Copyright>
      </Container>
    </ServicesWrapper>
  </>
)

export default Footer

import styled from "styled-components"
import { space, breakpoint } from "../styles/variables"

const Container = styled.div`
  margin: 0 auto;
  max-width: 1420px;
  padding: 0 ${space.xl};
  @media (max-width: ${breakpoint.sm}) {
    padding: 0 ${space.l};
  }
`

export default Container

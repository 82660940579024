import * as React from "react"
import Container from "./Container"
import { space, breakpoint, typography } from "../styles/variables"
import styled from "styled-components"

const Logo = styled.h1`
  font-size: ${typography.size.s2};
  font-weight: ${typography.weight.bold};
  margin-bottom: 0;
`

const Links = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  column-gap: ${space.xl};
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;

  li:hover {
    text-decoration: underline;
  }

  @media (max-width: ${breakpoint.md}) {
    font-size: ${typography.size.s1};
    text-transform: none;
    justify-content: space-around;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding: ${space.l} 0;
  }
`

const NavigationBarContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: ${space.xl} 0;
  @media (max-width: ${breakpoint.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    grid-row-gap: ${space.xl};
  }
`

const Header = ({ showNav = true }) => {
  const scrollTo = (selector, blockPosition = "start") => {
    const element = document.querySelector(selector)

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: blockPosition,
      })

      return true
    }
  }

  return (
    <header>
      <Container>
        <NavigationBarContainer>
          <Logo>WILL-DO By Riina</Logo>
          {showNav && (
            <Links>
              <li onClick={() => scrollTo("#services")}>Palvelut</li>
              <li onClick={() => scrollTo("#trainer")}> Valmentaja</li>
              <li onClick={() => scrollTo("#contact")}>Ota yhteyttä</li>
            </Links>
          )}
        </NavigationBarContainer>
      </Container>
    </header>
  )
}

export default Header

import { createGlobalStyle } from "styled-components"
import { typography, color, space } from "../styles/variables"
import "@fontsource/inter" // Defaults to weight 400 with all styles included.
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"

const reset = () => `
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0 !important;
  padding: 0;
}

a.anchor, a.anchor:hover, a.anchor:link {
  background: none !important;
}

figure {
  a.gatsby-resp-image-link {
    background: none;
  }

  span.gatsby-resp-image-wrapper {
    max-width: 100% !important;
  }
}
`

export const GlobalStyle = createGlobalStyle`
${reset()}


a {
    text-decoration: underline;
    color: ${color.black};
  }

  html {
    line-height: 1.5; /* 1 */
  }

  ul {
    padding: 0;
  }

  input, textarea {
    font-size: 100%;
    font-family: ${typography.type.primary};
  }
  button {
    font-family: ${typography.type.primary};
    font-size: ${typography.size.s2};
  }
  body {
    font-family: ${typography.type.primary};
    font-size: ${typography.size.s2};
    color: ${color.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    font-weight: ${typography.weight.regular};
  }
  h1 {
    line-height: 1.25; 
    font-size: ${typography.size.l2};
    margin-bottom: ${space.m};
    @media (max-width: 768px) {
      font-size: ${typography.size.l1};
     
  }
  }
  h2 {
    line-height: 1.25; 
    font-size: ${typography.size.m2};
    margin-bottom: ${space.m};
    text-transform: uppercase;
  }
  h3 {
    line-height: 1.25; 
    font-size: ${typography.size.l1};
    font-weight: ${typography.weight.bold};
    margin-bottom: ${space.m};
  }
  h4 {
    font-size: ${typography.size.s2};
    margin-bottom: ${space.s};
    font-weight: ${typography.weight.semibold};
    @media (max-width: 768px) {
      font-size: ${typography.size.s1};
  }
    
  }
  h5 {
    font-size: ${typography.size.s2};
    font-weight: ${typography.weight.bold};
    margin-bottom: ${space.s};
  }
  p {
    font-size: ${typography.size.s2};
    margin-top: 0;
    margin-bottom: ${space.m};
    @media (max-width: 768px) {
      font-size: ${typography.size.s2};
  }


  }

`

import * as React from "react"
import styled from "styled-components"
import { space, typography } from "../styles/variables"

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid #4c494a;
  padding-bottom: ${space.m};
  width: 80%;
  outline: none;
  color: white;
  font-size: ${typography.size.s3};

  &:focus {
    border-bottom: 1px solid white;
  }
`

const TextArea = styled.textarea`
  background: transparent;
  border: none;
  border-bottom: 1px solid #4c494a;
  padding-bottom: ${space.s};
  width: 80%;
  outline: none;
  color: white;
  font-size: ${typography.size.s3};

  &:focus {
    border-bottom: 1px solid white;
  }
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  color: white;
  text-transform: uppercase;
  font-size: ${typography.size.s3};
`

const ContactForm = () => (
  <form
    name="contact"
    action="/pages/success/"
    method="post"
    data-netlify-honeypot="bot-field"
    data-netlify="true"
  >
    <Input type="hidden" name="bot-field" />
    <Input type="hidden" name="form-name" value="contact" />
    <p>
      <Input type="text" placeholder="Nimi" name="name" />
    </p>
    <p>
      <Input type="tel" placeholder="Puhelinnumero" name="phonenumber" />
    </p>
    <p>
      <Input type="email" placeholder="Sähköposti" required name="email" />
    </p>
    <p>
      <TextArea name="message" placeholder="Viesti" required></TextArea>
    </p>
    <p>
      <Button type="submit">Lähetä</Button>
    </p>
  </form>
)

export default ContactForm
